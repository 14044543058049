
// import './App.css';

function App() {
  return (
    <main style={{maxWidth: '600px'}}>
      <h1>Flow State Software LLC</h1>
      <p>
        Flow State Software produces and operates an ecosystem micro-apps for the web.
      </p>
      <h2>apps</h2>
      <h3>app-0</h3>
      <p>
        Our premeir app is an easy solution to transfer funds from debit gift cards straight into your bank account. This app is in early development stages and is currently unnamed.
      </p>
      <h2>contact</h2>
      <p>
        All inquires can be directed to <a href="mailto:jacobwintr@gmail.com">jacobwintr@gmail.com</a>.
      </p>
      <h2>policies</h2>
      <p>Policies that appear on this page are to be superceded by the policies established by Flow State Software's individual services</p>
      <h3>refund and dispute policy</h3>
      <p>
        Flow State Software LLC merely creates software that enables current financial service technologies to fulfill specific services. All disputes are set to be managed by Flow State Softwares payment gateway provider <a href="stripe.com">Stripe</a>.
        Refunds can be requested by contacting us at <a href="mailto:jacobwintr@gmail.com">jacobwintr@gmail.com</a>, but refunds are not guaranteed.
      </p>
    </main>
  );
}

export default App;
